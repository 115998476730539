<template>
  <b-modal
    title="Update a popup"
    id="update-a-popup"
    centered
    ok-title="Update"
    cancel-title="Dismiss"
    :ok-disabled="isRenderingOverlay"
    no-close-on-backdrop
    ok-variant="success"
    no-close-on-ok
    @hidden="resetModal"
    @ok.prevent="okCallback(popupPayload)"
  >
    <b-overlay :show="isRenderingOverlay">
      <b-container fluid v-if="popupPayload">
        <b-form-row>
          <b-col cols="12">
            <text-input
              inputId="popupTitle"
              inputLabel="Popup Title"
              inputPlaceholder="Enter your popup title"
              v-model="popupPayload.title"
            ></text-input>
          </b-col>
          <b-col cols="12">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <b-img
                :src="generateImageUrl"
                style="object-fit: contain"
                class="rounded my-1"
                height="auto"
                width="330"
              ></b-img>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
              class="d-inline-blockmr-md-2"
              label="Select Your Image"
              label-for="imagePicker"
            >
              <b-form-file
                ref="updateFileInput"
                id="imagePicker"
                @input="setFile"
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="imagePreview">
            <div
              class="d-flex flex-column align-items-center justify-content-center my-1"
            >
              <b-button
                variant="transparent"
                size="sm"
                @click="deleteLocalImage"
              >
                <feather-icon icon="Trash2Icon" class="text-danger" size="20" />
              </b-button>

              <b-img
                :src="imagePreview"
                style="object-fit: contain"
                class="rounded"
                height="auto"
                width="330"
              ></b-img>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Popup Description"
              label-for="popupDescription"
            >
              <ckEditorMain
                id="popupDescription"
                ref="editorContent"
                @getEditorContent="setEditorContent"
                :defaultContent="popupPayload.description"
              ></ckEditorMain>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <text-input
              inputId="popupLink"
              inputLabel="Popup Link"
              inputPlaceholder="https://www.google.com"
              v-model="popupPayload.link"
            ></text-input>
          </b-col>
        </b-form-row>
      </b-container>
    </b-overlay>
  </b-modal>
</template>
<script>
export default {
  emits: ["resetUpdateModal"],
  watch: {
    tempPopupId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getSinglePopup();
        }
      },
    },
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    okCallback: {
      type: Function,
    },
    tempPopupId: {
      type: [Number, String, null],
    },
  },
  data() {
    return {
      popupPayload: null,
      imagePreview: null,
      showOverlay: false,
    };
  },
  methods: {
    setEditorContent(content) {
      this.popupPayload.description = content;
    },
    async getSinglePopup() {
      try {
        this.showOverlay = true;
        let _this = this;
        let getPopupById = new GetPopupById(_this);
        getPopupById.setRequestParam({ id: _this.tempPopupId });
        await getPopupById.fetch((res) => {
          _this.popupPayload = res;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.showOverlay = false;
      }
    },
    setFile() {
      if (
        this.$refs.updateFileInput &&
        this.$refs.updateFileInput.files &&
        this.$refs.updateFileInput.files[0]
      ) {
        this.popupPayload.mainImage = this.$refs.updateFileInput.files[0];
        this.imagePreview = URL.createObjectURL(this.popupPayload.mainImage);
      }
    },
    deleteLocalImage() {
      this.$refs.updateFileInput.value = "";
      URL.revokeObjectURL(this.imagePreview);
      this.imagePreview = null;
      this.popupPayload.mainImage = null;
    },
    resetModal() {
      this.$refs.updateFileInput.value = "";
      this.popupPayload = null;
      this.$emit("resetUpdateModal");
    },
  },
  computed: {
    generateImageUrl() {
      if (this.popupPayload && this.popupPayload.url) {
        return Helper.baseUrl + this.popupPayload.url;
      }
      return null;
    },
    isRenderingOverlay() {
      if (this.isLoading || this.showOverlay) {
        return true;
      }
      return false;
    },
  },
  components: {
    BModal,
    BOverlay,
    BImg,
    BContainer,
    BFormFile,
    BCol,
    BFormRow,
    BFormGroup,
    BButton,
    BBadge,
    TextInput,
    ckEditorMain,
  },
};
import TextInput from "@/views/components/Utilities/TextInput.vue";
import Helper from "@/libs/Helper";
import ckEditorMain from "@/views/components/ckEditorMain";
import { GetPopupById } from "@/libs/Api/ImportantNews";

import {
  BModal,
  BOverlay,
  BImg,
  BContainer,
  BFormFile,
  BCol,
  BFormRow,
  BFormGroup,
  BButton,
  BBadge,
} from "bootstrap-vue";
</script>
