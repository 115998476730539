import WebServiceRequest from "./WebServiceRequest";

class GetPopupList extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("ImportantNews/GetList");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class CreateNewPopup extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("ImportantNews/Create");
  }
  setFormData(data) {
    super.setFormData(data);
  }
}

class DeleteAPopup extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("ImportantNews/Delete");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class GetPopupById extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("ImportantNews/GetById");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class UpdateAPopup extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("ImportantNews/Update");
  }
  setFormData(data) {
    super.setFormData(data);
  }
}

export {
  GetPopupList,
  CreateNewPopup,
  DeleteAPopup,
  GetPopupById,
  UpdateAPopup,
};
