<template>
  <b-modal
    title="Create a new popup"
    id="create-new-popup"
    centered
    ok-title="Create"
    cancel-title="Dismiss"
    :ok-disabled="isLoading"
    no-close-on-backdrop
    ok-variant="success"
    no-close-on-ok
    @hidden="resetModal"
    @ok.prevent="okCallback(popupPayload)"
  >
    <b-overlay :show="isLoading">
      <b-container fluid>
        <b-form-row>
          <b-col cols="12">
            <text-input
              inputId="popupTitle"
              inputLabel="Popup Title"
              inputPlaceholder="Enter your popup title"
              v-model="popupPayload.title"
            ></text-input>
          </b-col>
          <b-col cols="12">
            <b-form-group
              class="d-inline-blockmr-md-2"
              label="Select Your Image"
              label-for="imagePicker"
            >
              <b-form-file
                ref="fileInput"
                id="imagePicker"
                @input="setFile"
              ></b-form-file>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="imagePreview">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <b-button
                variant="transparent"
                size="sm"
                @click="deleteLocalImage"
              >
                <feather-icon icon="Trash2Icon" class="text-danger" size="20" />
              </b-button>

              <b-img
                :src="imagePreview"
                style="object-fit: contain"
                class="rounded my-1"
                height="auto"
                width="330"
              ></b-img>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Popup Description"
              label-for="popupDescription"
            >
              <ckEditorMain
                id="popupDescription"
                ref="editorContent"
                @getEditorContent="setEditorContent"
                :defaultContent="popupPayload.description"
              ></ckEditorMain>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <text-input
              inputId="popupLink"
              inputLabel="Popup Link"
              inputPlaceholder="https://www.google.com"
              v-model="popupPayload.link"
            ></text-input>
          </b-col>
        </b-form-row>
      </b-container>
    </b-overlay>
  </b-modal>
</template>
<script>
export default {
  props: {
    okCallback: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      popupPayload: {
        title: null,
        mainImage: null,
        description: null,
        link: null,
      },
      imagePreview: null,
    };
  },
  methods: {
    setEditorContent(content) {
      this.popupPayload.description = content;
    },
    setFile() {
      if (
        this.$refs.fileInput &&
        this.$refs.fileInput.files &&
        this.$refs.fileInput.files[0]
      ) {
        this.popupPayload.mainImage = this.$refs.fileInput.files[0];
        this.imagePreview = URL.createObjectURL(this.popupPayload.mainImage);
      }
    },
    deleteLocalImage() {
      this.$refs.fileInput.value = "";
      URL.revokeObjectURL(this.imagePreview);
      this.imagePreview = null;
      this.popupPayload.mainImage = null;
    },
    resetModal() {
      if (this.imagePreview) {
        URL.revokeObjectURL(this.imagePreview);
        this.imagePreview = null;
      }
      this.popupPayload = {
        id: null,
        title: null,
        mainImage: null,
        description: null,
        link: null,
      };
    },
  },
  components: {
    BModal,
    BOverlay,
    BImg,
    BContainer,
    BFormFile,
    BCol,
    BFormRow,
    BFormGroup,
    BButton,
    TextInput,
    ckEditorMain,
  },
};
import TextInput from "@/views/components/Utilities/TextInput.vue";
import ckEditorMain from "@/views/components/ckEditorMain";
import {
  BModal,
  BOverlay,
  BImg,
  BContainer,
  BFormFile,
  BCol,
  BFormRow,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
</script>
