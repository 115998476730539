<template>
  <page-wrapper
    :isLoading="isLoading"
    :hasFilters="true"
    @submitFilters="getAllPopups"
  >
    <template #title> Popup List </template>
    <template #titleBtn>
      <b-button type="button" variant="success" @click="renderCreatePopupModal">
        Create New Popup
      </b-button>
    </template>
    <template #filters>
      <search-input
        @enter="getAllPopups"
        v-model="popupsFilters.title"
        inputId="titleSearch"
        inputLabel="Search Popup Title"
        inputPlaceholder="Popup Title"
      ></search-input>
      <search-input
        @enter="getAllPopups"
        v-model="popupsFilters.link"
        inputId="linkSearch"
        inputLabel="Search Popup Link"
        inputPlaceholder="Popup Link"
      ></search-input>
    </template>
    <custom-table
      :pageNumber="popupsFilters.pageNumber"
      :count="popupsFilters.count"
      :totalCount="totalCount"
      :tableItems="popupsList"
      :tableColumns="myTableColumns"
      @changePage="changePage"
    >
      <template #actions="data">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <b-button
            type="button"
            variant="transparent"
            size="sm"
            @click="renderPopupUpdateModal(data.item)"
          >
            <feather-icon icon="EditIcon" class="text-primary" size="20" />
          </b-button>
          <b-button
            type="button"
            variant="transparent"
            size="sm"
            @click="renderPopupDeleteModal(data.item)"
          >
            <feather-icon icon="Trash2Icon" class="text-danger" size="20" />
          </b-button>
        </div>
      </template>
    </custom-table>
    <!-- Create Modal Start -->
    <create-new-popup-modal
      :okCallback="createANewPopup"
      :isLoading="isLoading"
    ></create-new-popup-modal>
    <!-- Create Modal End -->
    <!-- Delete Modal Start -->
    <delete-popup-confirmation-modal
      :tempPopup="tempPopup"
      :okCallback="sendDeletePopupRequest"
      :isLoading="isLoading"
    ></delete-popup-confirmation-modal>
    <!-- Delete Modal End -->
    <!-- Update Modal Start -->
    <update-popup-modal
      @resetUpdateModal="resetUpdateModal"
      :tempPopupId="tempPopupId"
      :isLoading="isLoading"
      :okCallback="sendPopupUpdateRequest"
    ></update-popup-modal>
    <!-- Update Modal End -->
  </page-wrapper>
</template>

<script>
export default {
  title: "Popups",
  mounted() {
    this.getAllPopups();
  },
  data() {
    return {
      isLoading: true,
      popupsFilters: {
        pageNumber: 1,
        count: 10,
        id: null,
        title: null,
        readCount: null,
        link: null,
      },
      popupsList: [],
      totalCount: null,
      tempPopup: null,
      tempPopupId: null,
      myTableColumns: [
        {
          key: "title",
          label: "Popup Title",
        },
        {
          key: "readCount",
          label: "Views",
        },
        {
          key: "link",
          label: "Popup Link",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
    };
  },
  methods: {
    changePage(pageNumber) {
      this.popupsFilters.pageNumber = pageNumber;
      this.getAllPopups();
    },
    renderPopupUpdateModal(data) {
      this.tempPopupId = JSON.parse(JSON.stringify(data.id));
      this.$bvModal.show("update-a-popup");
    },
    renderPopupDeleteModal(data) {
      this.tempPopup = JSON.parse(JSON.stringify(data));
      this.$bvModal.show("delete-a-popup-confimration-modal");
    },
    editDeleteCondition(data) {
      return true;
    },
    renderCreatePopupModal() {
      this.$bvModal.show("create-new-popup");
    },
    createFormDataPayload(payload) {
      let formData = new FormData();
      Object.keys(payload).forEach((key) => {
        if (key != "url") {
          if (payload[key]) {
            formData.append(key, payload[key]);
          }
        }
      });
      return formData;
    },
    resetUpdateModal() {
      this.tempPopupId = null;
    },
    async sendPopupUpdateRequest(payload) {
      try {
        this.isLoading = true;
        let _this = this;
        let updateAPopup = new UpdateAPopup(_this);
        updateAPopup.setFormData(_this.createFormDataPayload(payload));
        await updateAPopup.sendFormData((res) => {
          if (res.data.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Updated!",
                icon: "CheckIcon",
                variant: "success",
                text: "Popup Updated successfully.",
              },
            });
            _this.$bvModal.hide("update-a-popup");
            _this.getAllPopups();
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.data.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async sendDeletePopupRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let deleteAPopup = new DeleteAPopup(_this);
        deleteAPopup.setRequestParam({
          id: _this.tempPopup.id,
        });
        await deleteAPopup.fetch((res) => {
          if (res.isSuccess) {
            _this.$bvModal.hide("delete-a-popup-confimration-modal");
            _this.tempPopup = null;
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Deleted!",
                icon: "CheckIcon",
                variant: "success",
                text: "Popup Deleted successfully.",
              },
            });
            _this.getAllPopups();
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.data.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async createANewPopup(payload) {
      try {
        this.isLoading = true;
        let _this = this;
        let createNewPopup = new CreateNewPopup(_this);
        createNewPopup.setFormData(_this.createFormDataPayload(payload));
        await createNewPopup.sendFormData((res) => {
          if (res.data.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Created!",
                icon: "CheckIcon",
                variant: "success",
                text: "Popup created successfully.",
              },
            });
            _this.$bvModal.hide("create-new-popup");
            _this.getAllPopups();
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.data.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getAllPopups() {
      try {
        this.isLoading = true;
        let _this = this;
        let getPopupList = new GetPopupList(_this);
        getPopupList.setRequestParamDataObj(_this.popupsFilters);
        await getPopupList.fetch((res) => {
          _this.totalCount = res.total;
          _this.popupsList = res.data;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    PageWrapper,
    SearchInput,
    CustomTable,
    DeletePopupConfirmationModal,
    CreateNewPopupModal,
    UpdatePopupModal,
    BButton,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
import CreateNewPopupModal from "@/views/components/Popup/CreateNewPopupModal.vue";
import UpdatePopupModal from "@/views/components/Popup/UpdatePopupModal.vue";
import DeletePopupConfirmationModal from "@/views/components/Popup/DeletePopupConfirmationModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { BButton } from "bootstrap-vue";
import {
  GetPopupList,
  CreateNewPopup,
  DeleteAPopup,
  UpdateAPopup,
} from "@/libs/Api/ImportantNews";
</script>
